import React from "react";
import ReactDom from "react-dom"
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {
    Screen
} from "./components/screen"
import './assets/styles.css';

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Screen}></Route>
                <Redirect to="/"></Redirect>
            </Switch>
        </BrowserRouter>
    )
};

const app = document.querySelector('#app');

ReactDom.render(<App />, app);
