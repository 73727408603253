import React from 'react';

const Screen = () => {
    return (
        <div
            className="main">
            <div
                className="top bar">
                    <img  
                    src="https://i.ibb.co/0D11JmC/Asset-14.png"></img>
            </div>
            <div
                className="content"
            >
                <div
                    className="mission box"
                >
                    {/* <img src="https://i.ibb.co/zrvFCSq/LogoBG.png"></img> */}
                    
                    <h1>
                        Mission Statement :
                    </h1>
                    <p>
                        "Our mission is to support diverse, inclusive organizations 
                        that are committed to the betterment of their local communities 
                        and/or the greater world. We also work to provide aid to individuals 
                        who have found themselves in need of help."
                    </p>
                </div>
                <div
                    className="contact box"
                >
                    {/* <img src="https://i.ibb.co/zrvFCSq/LogoBG.png"></img> */}
                    <h1>
                        Contact Us :
                    </h1>
                    <p>
                        For further information or inquiries, please contact us at the email below -
                        
                    </p>
                    <a href="mailto:info@tetradfoundation.com">info@tetradfoundation.com</a>
                </div>
            </div>
        </div>
    )
};

export { Screen };